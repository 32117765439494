// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import email from "@src/views/Prepare/Capture/Notes/store/reducer";

import books from "@src/views/Books/store/reducer";
import videos from "@src/views/Videos/store/reducer";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore"; // <- needed if using firestore

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  navbar,
  layout,
  books,
  videos,
  email,
});

export default rootReducer;
