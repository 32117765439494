// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faTags,
  faPencilAlt,
  faCheckSquare,
  faPaintBrush,
  faImage,
  faBell,
  faCaretSquareDown,
  faTimes,
  faAlignLeft,
  faTrash,
  faArchive,
  faInfoCircle,
  faPlus,
  faFire,
  faBook,
  faIndustry,
  faDraftingCompass,
  faBullseye,
  faHeartbeat,
  faCogs,
  faDollarSign,
  faSeedling,
  faComment,
  faUser,
  faSearch,
  faSave,
  faCheckCircle,
  faTimesCircle,
  faInfo,
  faBookOpen,
  faList,
  faCog,
  faSearchPlus,
  faSearchMinus,
  faFolder,
  faVideo,
  faTextHeight,
  faTextWidth,
  faAlignJustify,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowLeft,
  faTags,
  faPencilAlt,
  faCheckSquare,
  faPaintBrush,
  faImage,
  faBell,
  faCaretSquareDown,
  faTimes,
  faAlignLeft,
  faTrash,
  faArchive,
  faInfoCircle,
  faPlus,
  faIndustry,
  faDraftingCompass,
  faBullseye,
  faHeartbeat,
  faCogs,
  faDollarSign,
  faSeedling,
  faFire,
  faBook,
  faComment,
  faUser,
  faSearch,
  faSave,
  faCheckCircle,
  faTimesCircle,
  faInfo,
  faBookOpen,
  faList,
  faCog,
  faSearchPlus,
  faSearchMinus,
  faFolder,
  faVideo,
  faTextHeight,
  faTextWidth,
  faAlignJustify
);

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
const fbConfig = {
  apiKey: "AIzaSyCjJZeYbmvaD5-njYolKaqVQBgjqhTyO3s",
  authDomain: "productify-6689a.firebaseapp.com",
  databaseURL: "https://productify-6689a.firebaseio.com",
  projectId: "productify-6689a",
  storageBucket: "productify-6689a.appspot.com",
  appId: "1:321147125484:android:557a9fee9a12e9d4",
  messagingSenderId: "321147125484",
};
const rrfConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  firebaseStateName: "firebase",
}; // react-redux-firebase config

// Initialize firebase instance
firebase.initializeApp(fbConfig);
firebase.firestore();
firebase.database();
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};
ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Suspense>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
