const initialState = {
    list: [],
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case "ON_BOOKS_LIST":
        return { ...state, list: action.payload.books };
      default:
        return state;
    }
  }