const initialState = {
  data: { folder: [], files: [], path: "" },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "ON_FOLDERS_LIST":
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
